export const environments = {
    auth0: window.location.hostname.includes('guesthub.io')
        ? {
            domain: "norelian.auth0.com",
            client_id: "vvvYy3_e3bbXchiPTEEEF_G2WCjjUT8X",
            audience: "https://guesthub.norelian.com",
            redirect_uri: `${window.location.origin}`
        } : {
            domain: "norelian-dev.auth0.com",
            client_id: "96YHrGR4uJx5RHaGkt7yPfwOCEMvBVhI",
            audience: "https://guesthub-dev.norelian.com",
            redirect_uri: `${window.location.origin}`
        },
    authorizationApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('administration.', 'authorization-api.')}`
        : `https://authorization-api.qa.norelian.com`,
    guestAppApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'guestapp-api.')}`
        : `http://localhost:3052`,//`https://guestapp-api.qa.norelian.com`
    reservationsWeb: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'reservations.')}`
        : `https://reservations.qa.norelian.com`,
    reservationsApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'reservations-api.')}`
        : `https://reservations-api.qa.norelian.com`,
    filesApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'files-api.')}`
        : `https://files-api.qa.norelian.com`,
    eventsApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
        ? `${window.location.origin.replace('guestapp.', 'events-api.')}`
        : `https://events-api.qa.norelian.com`,
    checkInApi: window.location.hostname.includes('guesthub.io') || window.location.hostname.includes('norelian.com')
    ? `${window.location.origin.replace('guestapp.', 'check-in-api.')}`
    : `https://events-api.qa.norelian.com`,
};